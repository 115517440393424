/*!
 *    Personal website of Sefa Eyeoglu
 *    Copyright (C) 2018-2022  Sefa Eyeoglu <contact@scrumplex.net>
 *
 *    This program is free software: you can redistribute it and/or modify
 *    it under the terms of the GNU Affero General Public License as published by
 *    the Free Software Foundation, either version 3 of the License, or
 *    (at your option) any later version.
 *
 *    This program is distributed in the hope that it will be useful,
 *    but WITHOUT ANY WARRANTY; without even the implied warranty of
 *    MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *    GNU Affero General Public License for more details.
 *
 *    You should have received a copy of the GNU Affero General Public License
 *    along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

@import "variables";

noscript {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $colorBase;
    padding: 10px;
    text-align: center;
    z-index: 1000;

    @media (prefers-color-scheme: dark) {
        background-color: $colorBaseDark;
    }
}

.wrapper {
    padding-top: 100px;
    padding-bottom: 100px;
}

body.scroll {
    padding-bottom: 0;
}

.sheet {
    display: block !important;
    opacity: 1;
    animation: none;
    top: initial !important;
    position: relative !important;
    transform: none !important;
}

img[data-src] {
    display: none;
}

.scroll-indicator {
    display: none;
}
